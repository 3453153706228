import { webClient } from "@api/web/WebClient";
import { ReshopperCard as Card, ReshopperCardContent as CardContent } from "@components/web/Card";
import { withWebLayout } from "@components/web/Decorators";
import { queryApi } from "@hooks/api";
import { useQueryParameter } from "@hooks/navigation";
import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";

export default withWebLayout(() => {
	const [itemId] = useQueryParameter("id");

	try {
		const objectionResponse = queryApi(
			async (options) => itemId && await webClient().webItemsReportObjectionPost(itemId, options),
			[itemId],
			true);

		useEffect(
			() => {
				if (!objectionResponse)
					return;

				window.location.href = ("/items/object-to-report-success");
			},
			[objectionResponse]);
	}
	catch (e: unknown) {
		alert("Your objection could not be submitted.");
	}

	return <Card style={{
		width: 300,
		alignSelf: 'center',
		marginTop: 48
	}}>
		<CardContent>
			<CircularProgress />
		</CardContent>
	</Card>
});